










































































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import moment from 'moment'
import { vxm } from '@/store'

@Component({
  components: {},
})
export default class ReportsProductsList extends Vue {
  selected = []

  refreshTransferStatusInfoLoading = false
  sendReportLoading = false
  deleteLoading = false

  sendReportDialog = false
  sendReportData = {
    id: null,
    transferMethodId: null,
    transferMethodName: null,
    transferFrequencyName: null,
    exportFileTypeName: null,
    transferHost: null,
    transferUser: null,
    transferPassword: null,
    transferPort: null,
    transferEmails: null,
    transferFileTypeName: null,
    transferStatusId: null,
    transferStatusName: null,
    transferStatusMessage: null,
    transferRequestTime: null,
    transferFinishedTime: null,
    transferUserProfile: {
      fullname: null,
    },
  }

  moment = moment

  headers = []
  reports = []
  search = ''

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: 15,
    sortBy: [],
  }

  created() {
    this.getInitialData()
  }

  getInitialData() {
    this.$axios.get('/v3/reports/products/list/get_initial_data').then((response) => {
      this.headers = response.data.data.headers
      this.reports = response.data.data.reports
    })
  }

  deleteReport(item) {
    this.deleteReports(item)
  }

  deleteReports(item) {
    if (confirm(this.$t('c:common:Are you sure you want to delete this item(s)?').toString())) {
      this.deleteLoading = true
      let selectedRows
      if (item === null) {
        selectedRows = this.selected
      } else {
        selectedRows = [item]
      }

      const deleteData = {
        selected_rows: selectedRows,
      }
      this.$axios
        .delete('/v3/reports/products/list/delete_reports', { data: deleteData })
        .then((response) => {
          for (let i = 0; i < selectedRows.length; i++) {
            const index = this.reports.indexOf(selectedRows[i])
            this.reports.splice(index, 1)
          }
        })
        .finally(() => {
          this.deleteLoading = false
        })
    }
  }

  openSendReportDialog(item) {
    const index = this.reports.indexOf(item)
    this.sendReportData = this.reports[index]

    this.sendReportDialog = true
  }

  refreshTransferStatusInfo() {
    this.$axios.get('/v3/reports/products/list/get_report_data/' + this.sendReportData.id).then((response) => {
      this.sendReportData = response.data.data.report
      // Not really efficient, should come up with a better solution
      // We need to update the already returned rows with new transfer info
      this.getInitialData()
    })
  }

  sendReport() {
    this.sendReportLoading = true
    const data = {
      reportId: this.sendReportData.id,
    }

    this.$axios
      .post('/v3/reports/products/list/queue_send_report', data)
      .then((response) => {
        vxm.alert.success({
          content: this.$t('c:report-products-list:Report has been added to queue for transfer') as string,
          title: this.$t('c:common:Success') as string,
        })
        this.refreshTransferStatusInfo()
      })
      .catch((err) => {
        err.response.data.errors.forEach((v, i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
      .finally(() => {
        this.sendReportLoading = false
      })
  }
}
